const formatter = new Intl.NumberFormat("en-AU", {});

export const formatNumber = formatter.format.bind(formatter);

export const formatOptionalNumber = (value: number | undefined) => {
  if (value === undefined) {
    return "—";
  }

  return formatNumber(value);
};

export const formatRange = formatter.formatRange.bind(formatter);
