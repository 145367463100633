import defaults from "lodash/defaults";
import isEqual from "lodash/isEqual";
import type { ParsedUrlQuery, ParsedUrlQueryInput } from "querystring";

export const SEARCH_RESULTS_TERM_ID = "q";

export interface PageQuery {
  page?: number;
  perPage?: number;
}

export const defaultPageQuery: PageQuery = {
  page: 1,
  perPage: 10,
} as const;

export type ParsedUrlQueryValue = string | string[] | undefined;

export const getNumberFromQuery = (
  value: ParsedUrlQueryValue,
): number | undefined => {
  if (typeof value !== "string") {
    return undefined;
  }

  const number = parseInt(value, 10);

  if (!Number.isFinite(number)) {
    return undefined;
  }

  return number;
};

export const getStringFromQuery = (
  value: ParsedUrlQueryValue,
): string | undefined => {
  if (typeof value !== "string") {
    return undefined;
  }

  return value;
};

const validBooleanStrings = ["true", "false"];

export const getBooleanFromQuery = (
  value: ParsedUrlQueryValue,
): boolean | undefined => {
  // Ignore undefined values
  if (value === undefined) {
    return undefined;
  }

  // Ignore string array values
  if (typeof value !== "string") {
    return undefined;
  }

  // Only cast "true" or "false" to a boolean
  if (!validBooleanStrings.includes(value)) {
    return undefined;
  }

  return value === "true";
};

export const getStringArrayFromQuery = (
  value: ParsedUrlQueryValue,
): string[] | undefined => {
  if (typeof value === "string") {
    return [value];
  }

  if (!Array.isArray(value)) {
    return undefined;
  }

  return value;
};

export const pluckPageQueryFromQuery = (
  query: ParsedUrlQuery,
  defaultQuery = defaultPageQuery,
): PageQuery => {
  return defaults<Record<string, never>, Partial<PageQuery>, PageQuery>(
    {},
    {
      page: getNumberFromQuery(query.page),
      perPage: getNumberFromQuery(query.perPage),
    },
    defaultQuery,
  );
};

export const getParamsFromPageQuery = ({
  page,
  perPage,
}: PageQuery): PageQuery => {
  return {
    page,
    perPage,
  };
};

export const getQueryFromPageQuery = (
  pageQuery: PageQuery,
  defaultQuery: PageQuery = defaultPageQuery,
): ParsedUrlQueryInput => {
  if (isEqual(pageQuery, defaultQuery)) {
    return {};
  }

  const { page, perPage } = pageQuery;

  return { page, perPage };
};
